import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from '../../assets/images/supportDevice/1.png';
import img2 from '../../assets/images/supportDevice/2.png';
import img3 from '../../assets/images/supportDevice/3.png';
import img4 from '../../assets/images/supportDevice/4.png';
import img5 from '../../assets/images/supportDevice/5.png';
import img6 from '../../assets/images/supportDevice/6.png';
import img7 from '../../assets/images/supportDevice/7.png';
import img8 from '../../assets/images/supportDevice/8.png';
import img9 from '../../assets/images/supportDevice/9.png';


export default function CaroucelDevices() {
  const supportDeviceData = [
    { id: 1, image: img1 },
    { id: 2, image: img2 },
    { id: 3, image: img3 },
    { id: 4, image: img4 },
    { id: 5, image: img5 },
    { id: 6, image: img6 },
    { id: 7, image: img7 },
    { id: 8, image: img8 },
    { id: 9, image: img9 }
  ];
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3500,
    nextArrow: <div className="hidden"></div>,
    prevArrow: <div className="hidden"></div>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="bg-[#3f81f8] rounded py-8">
      <div className="text-center ">
        <h1 className="text-lg text-white md:text-2xl lg:text-3xl font-bold font-sans">Stream Anywhere, Any Device.</h1>
        <p className="text-sm lg:text-lg text-gray-50">Experience our IPTV service on your preferred devices without limitations.</p>
      </div>
      <div className="md:flex md:justify-center md:items-center ">
        <Slider className="md:w-[42pc] lg:w-[55pc] align-slider" {...settings} arrows={false}>
          {
            supportDeviceData.map((item) => {
              return (
                <div key={item.id} className="!flex justify-center">
                  <img className=" w-[10pc] object-cover image-carousel-device" src={item.image} />
                </div>

              )
            })
          }
        </Slider>
      </div>
    </div>
  )
}
