import React from 'react'
import { Avatar, Dropdown, Navbar } from 'flowbite-react';
import axios from '../../../plugins/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
export default function ({ toggleSidebar }) {
    const userDataString = localStorage.getItem('userData');
    const userData = JSON.parse(userDataString);
    const navigate = useNavigate()
    const notify = (message) => {
        toast.success(message, {});
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/logout');
            notify(response.data.message)
            localStorage.removeItem('userData');
            localStorage.removeItem('token');
            navigate('/')
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <nav className="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-50">
            <ToastContainer
                className='pt-24'
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Navbar fluid rounded>
                <button
                    className="md:hidden p-2 focus:outline-none"
                    onClick={toggleSidebar}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-6 w-6 text-gray-500"
                    >
                        <path
                            strokeLinecap="round"
                           
                            strokeWidth="2"
                            d="M4 6h16M4 12h16m-7 6h7"
                        />
                    </svg>
                </button>
                <Navbar.Brand href="#">
                    {/* <img src="/favicon.svg" className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" /> */}
                    <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">GateStreame</span>
                </Navbar.Brand>
                <div className="flex md:order-2">
                    <Dropdown
                        arrowIcon={false}
                        inline
                        label={
                            <Avatar alt="User settings" img="https://flowbite.com/docs/images/people/profile-picture-5.jpg" rounded />
                        }
                    >
                        <Dropdown.Header>
                            <span className="block text-sm">{userData.name}</span>
                            <span className="block truncate text-sm font-medium">{userData.email}</span>
                        </Dropdown.Header>
                        {/* <Dropdown.Item>Dashboard</Dropdown.Item>
                <Dropdown.Item>Settings</Dropdown.Item>
                <Dropdown.Item>Earnings</Dropdown.Item> */}
                        <Dropdown.Divider />
                        <form onClick={handleSubmit}>
                            {/* <Dropdown.Item className='text-red-500' >Sign out</Dropdown.Item> */}
                            <Dropdown.Item >
                                <button
                                    type="submit"
                                    className="w-full text-white bg-red-600 hover:bg-red-800  font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                >
                                    Sign in
                                </button></Dropdown.Item>
                        </form>
                    </Dropdown>
                    <Navbar.Toggle />
                </div>
            </Navbar>
        </nav>
    )
}
