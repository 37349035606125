import React from 'react';
import about from '../assets/images/about/1.png'
import { FaArrowRightLong } from "react-icons/fa6";
import { Button } from 'flowbite-react';
import { Link, useNavigate } from 'react-router-dom';
import FooterComponent from '../navigation/Footer';
import HeadContent from '../helper/HeadContent';

const columnsData = [
     {
          title: '01',
          subTitle: 'Subscribe & Enjoy',
          content: 'Choose from our flexible subscription durations: 1 month, 3 months, 6 months, or a year, and let the entertainment journey begin.',
     },
     {
          title: '02',
          subTitle: 'Rapid Activation',
          content: 'Your account activation is just around the corner, expected within 15 to 30 minutes. Keep an eye on your inbox, including the spam folder, and reach out on WhatsApp for expedited assistance.',
     },
     {
          title: '03',
          subTitle: 'Immerse in IPTV',
          content: 'Activate your subscription and immerse yourself in the symphony of IPTV. Experience a vast array of channels, captivating films, binge-worthy series, and the exhilaration of live matches.',
     },
];
export const About = () => {
     const title = 'About';
     const description = 'Gatestreame: Elevate Your Entertainment. Explore our premium IPTV services for limitless viewing pleasure. Choose from a variety of subscription plans tailored to your preferences. Join us now for seamless streaming and exclusive content.'
     return (
          <div >
               <HeadContent title={title} description={description} />
               <div className="container mx-auto p-4">
                    <div className="flex flex-col lg:flex-row text-stone-200">
                         <div className="lg:w-1/2 lg:pr-4">
                              <h1 className="text-4xl font-bold mb-4">About Us<span className='text-blue-400'>.</span> </h1>
                              <p className='text-justify'>
                                   Welcome to GateStreame, your gateway to unparalleled entertainment. We specialize
                                   in delivering a seamless IPTV experience,
                                   bringing you the latest movies and live football matches with unmatched convenience.
                              </p>
                              <p className='font-bold mt-8 text-justify text-stone-100'>
                                   Ready to redefine your entertainment journey? Choose GateStreame plans and immerse yourself in a world where
                                   movies and live sports seamlessly converge.
                                   Elevate your viewing experience – it's not just a plan; it's your ticket to unparalleled entertainment.
                              </p>
                              <div className="pt-14 flex justify-start md:justify-end">
                                   <Link to="/#plan" >
                                        <Button color="blue" >
                                             Choose plan
                                             <FaArrowRightLong className="ml-2 h-5 w-5" />
                                        </Button>
                                   </Link>
                              </div>
                         </div>
                         <div className="lg:w-1/2 lg:pl-4 mt-4 lg:mt-0">
                              <img src={about} alt="Movie" className="w-full h-auto" />
                         </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:space-x-4 gap-4">
                         {columnsData.map((item, index) => (
                              <div key={index} className="md:w-1/3 mb-4 md:mb-0 ">
                                   <div className='flex items-center font-mono '>
                                        <h2 className="text-6xl font-semibold mb-2 text-[#2f63d7] ">{item.title} </h2>
                                        <span className='text-2xl text-[#2f63d7] font-semibold pl-4'>{item.subTitle}</span>
                                   </div>
                                   <p className="text-stone-200 text-justify ">{item.content}</p>
                              </div>
                         ))}
                    </div>
               </div>

               {/* <div className="mt-8 ">
                    <FooterComponent />
               </div> */}
          </div>
     );
};
