import { Navbar } from 'flowbite-react';
import { Link, useLocation } from 'react-router-dom';
import { nav } from './Nav';
import { AuthProvider } from '../auth/Auth';

export default function Component() {
    const { user } = AuthProvider();
    const location = useLocation();
    return (
        <div id='navbar'>
            <Navbar fluid className=" text-white p-6 container mx-auto " style={{ backgroundColor: 'transparent' }}>
                <Navbar.Brand as={Link} to="/">
                    <span className="self-center whitespace-nowrap text-2xl font-semibold ">GateStream<span className='text-blue-500' style={{ fontFamily: 'cursive', fontSize: '20px' }}>e.</span></span>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    {nav.map((route, index) => (
                        (!route.isPrivate && route.isMenu) || (user.isAuth && route.isMenu) ? (
                            <Navbar.Link as={Link} key={index} to={route.path}
                                className={`text-white text-[16px] hover:text-blue-600  md:hover:text-blue-500 sm:hover:bg-neutral-400 ${location.pathname === route.path ? '' : ''}`}
                            >
                                {route.name}
                            </Navbar.Link>
                        ) : null
                    ))}
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}
