import { Home } from "../pages/Home"
import { About } from "../pages/About"
import Contact from "../pages/Contact"
import NotFound from "../pages/components/404Page/NotFound"
import Login from "../pages/Login"
import Dashbord from "../pages/admin/Dashbord"
import Plans from "../pages/admin/Plans"
import PlanPay from "../pages/components/PlanPay"
import Checkout from "../pages/components/Checkout"
import PayementSuccess from "../pages/components/payement/PayementSuccess"

export const nav = [
    { path: "/", name: "Home", element: <Home />, isMenu: true, isPrivate: false },
    { path: "/#plan", name: "Pricing", isMenu: true, isPrivate: false },
    { path: "/about", name: "About", element: <About />, isMenu: true, isPrivate: false },
    { path: "/contact", name: "Contact", element: <Contact />, isMenu: true, isPrivate: false },
    { path: "/not-found", name: "not-found", element: <NotFound />, isMenu: false, isPrivate: false },
    { path: "/admin-stream", name: "login", element: <Login />, isMenu: false, isPrivate: false },
    
    { path: "/checkout", name: "checkout", element: <Checkout />, isMenu: false, isPrivate: false },
    { path: "/payment-success", name: "payment-success", element: <PayementSuccess />, isMenu: false, isPrivate: false },

    { path: "/admin/dashbord", name: "Dashbord", element: <Dashbord />, isMenu: true, isPrivate: true },
    { path: "/admin/plans", name: "Plan", element: <Plans />, isMenu: true, isPrivate: true },
]