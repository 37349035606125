import { createContext, useContext, useEffect, useState } from "react";
import { RanderRoutes } from "../navigation/RanderRoutes";
import Component from "../navigation/NavBar2";
import FooterComponent from "../navigation/Footer";
import { useNavigate } from "react-router-dom";
import NavBar from "../pages/admin/components/NavBar";
import SideBar from "../pages/admin/components/SideBar";

const AuthContext = createContext()
export const AuthProvider = () => useContext(AuthContext)

export function Auth() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const token = localStorage.getItem('token');
    const isAuth = !!token;
    const navigate = useNavigate();
    const [user, setUser] = useState({ name: "", isAuth });
    useEffect(() => {
        if (isAuth) {
            navigate('/not-found');
        }
    }, [isAuth]);
    useEffect(() => {
        if (token) {
            navigate('/admin/dashbord');
            setUser({ name: "admin", isAuth: true });
        }
    }, [token]);

    return (

        <AuthContext.Provider value={{ user }}>
            <>
                {
                    !isAuth ? <Component /> : <NavBar toggleSidebar={toggleSidebar} />
                }
                {!isAuth ? <div ><RanderRoutes /></div> : <main className="p-4 md:ml-64 h-auto pt-24"><RanderRoutes /> </main>}
                {
                    !isAuth ? <FooterComponent /> : <SideBar isSidebarVisible={isSidebarVisible} />
                }

            </>
        </AuthContext.Provider>

    )
}
