// api.js
import axios from 'axios';

const api = axios.create({
    // Add your base URL or other configurations here
     baseURL: 'https://gatestreame.com/backend/public/api/',
    // baseURL: 'http://127.0.0.1:8000/api/',
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
});

// Add request interceptor to send authorization header
api.interceptors.request.use(config => {
    const token = localStorage.getItem('token');

    if (token) {
        config.headers = config.headers || {};
        config.headers.Authorization = token ? `Bearer ${token}` : '';

        // config.headers.Authorization = token ? `Bearer ${JSON.parse(token)}` : '';
    }

    return config;
});

// Add response interceptor to handle 401 response
api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // Handle 401 response (e.g., logout user and redirect to login page)
            localStorage.removeItem('userData');
            localStorage.removeItem('token');
            // Redirect to login page
            // window.location.href = '/';

        } else {
            return Promise.reject(error);
        }
    }
);

export default api;
