import React from 'react'
import { RenderMenu } from '../../../navigation/RanderRoutes'

export default function SideBar({ isSidebarVisible }) {

    return (
        <aside
            className={`fixed top-0 left-0 z-40 w-64 h-screen pt-16 transition-transform ${isSidebarVisible ? '-translate-x-full' : 'md:translate-x-0'} bg-white border-r border-gray-200 md:translate-x-0 `}
            aria-label="Sidenav"
            id="drawer-navigation"
        >
            <div className="overflow-y-auto py-5  h-full bg-white">
                <ul className="space-y-4">
                    <li className="flex items-center  text-base font-medium text-gray-900 rounded-lg  ">
                        <span className="ml-3"><RenderMenu /></span>
                    </li>
                </ul>

            </div >

        </aside >)
}
