import React, { useEffect, useState } from 'react';
import { fetchNowPlaying, BASE_URL_IMAGE } from '../../services/apiService';
import { Card } from 'flowbite-react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function CaroucelMovie() {
    const [nowPlaying, setNowPlaying] = React.useState(null);
    const [isLooding, setIslooding] = React.useState(false)
    const [data, setData] = useState([1, 2, 3, 4, 5]);
    useEffect(() => {
        async function fetchData() {
            try {
                setIslooding(false)
                const nowPlayingData = await fetchNowPlaying();
                setNowPlaying(nowPlayingData);
                setIslooding(true)
            } catch (error) {
                console.error('Error:', error);
                setIslooding(false)
            }
        }
        fetchData();
    }, []);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        autoplay: true,
        focusOnSelect: true,
        speed: 2000,
        autoplaySpeed: 3500,
        nextArrow: <div className="hidden"></div>,
        prevArrow: <div className="hidden"></div>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };
    return (
        <div className='container px-4'>
            {
                isLooding ? <Slider className="" {...settings} arrows={false}>
                    {nowPlaying?.map((item) => (
                        <div className='px-2' href="#" key={item.id}>
                            <img className='rounded' src={BASE_URL_IMAGE + item.poster_path} height='100px' width='100%' />
                        </div>
                    ))}
                </Slider> :

                    <Slider  {...settings} arrows={false}>
                        {data.map((item) => (
                            <div key={item}>
                                <div role="status" className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                                    <div className="flex xxx items-center justify-center w-48 h-64   bg-gray-300 rounded  dark:bg-gray-700">
                                        <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>

            }
        </div >
    )
}
