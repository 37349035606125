import { useLocation } from "react-router-dom"
import {
    requiredValidator,
    emailValidator,
    phoneNumberValidator,
    alphaValidator,
} from '../../helper/validators';
import axios from '../../plugins/axios';
import { useState } from "react";
import { Modal, Button } from "flowbite-react";
import { GiTimeTrap } from "react-icons/gi";



function generateReferenceId(length = 16) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let refId = '';

    for (let i = 0; i < length; i++) {
        refId += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return refId;
}
// function DialogPayment() {
//     const [openModal, setOpenModal] = useState(true)
//     return (
//         <>
//             <Modal show={openModal} onClose={() => setOpenModal(false)}>
//                 <Modal.Header>Waiting For Payment</Modal.Header>
//                 <Modal.Body>
//                     <div className="space-y-6">
//                         <div className="flex justify-center">
//                             <GiTimeTrap size={100} color="#fea500" />
//                         </div>
//                         <p className="text-center text-xl font-bold leading-relaxed text-gray-500">
//                             Please complete your payment process.
//                         </p>

//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button color="gray" onClick={() => setOpenModal(false)}>
//                         Pay Now
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </>
//     )
// }

export default function Checkout() {
    const location = useLocation()
    const { state } = location;
    const { price, namePlan, idPlan } = state;
    const [formErrors, setFormErrors] = useState({
        full_name: '',
        email: '',
        phone: '',

    });
    const handleSubmit = async (event) => {
        event.preventDefault();
        const refId = generateReferenceId();
        const formData = {
            reference: refId,
            plan_id: idPlan,
            full_name: event.target.full_name ? event.target.full_name.value : '',
            email: event.target.email ? event.target.email.value : '',
            phone: event.target.phone ? event.target.phone.value : '',

        };

        // Use the validators on the form data
        const errors = {
            full_name: requiredValidator(formData.full_name) || alphaValidator(formData.full_name),
            email: requiredValidator(formData.email) || emailValidator(formData.email),
            phone: requiredValidator(formData.phone) || phoneNumberValidator(formData.phone),
        };

        setFormErrors(errors);

        if (Object.values(errors).some((error) => error)) {
            return;
        }

        try {
            const response = await axios.post('/invoice', formData);
            const ref = response.data.data.reference
            event.target.full_name.value = ''
            event.target.email.value = ''
            event.target.phone.value = ''
            const redirectUrl = `https://freelance.gatestreame.com/payment?ref=${ref}`;
            window.open(redirectUrl, '_blank');

        } catch (error) {

            console.error(error)
        }
    };
    return (
        <div className="container mx-auto p-4">
            <div className=" text-stone-200">
                <div className="lg:w-1/2 lg:pr-4">
                    <h1 className="text-4xl font-bold mb-4">Checkout<span className='text-blue-400'>.</span> </h1>
                </div>
                {/* {DialogPayment()} */}
                <div className="py-4 border md:mx-20 rounded-md text-xl" >
                    {/* <h5>Billing details</h5> */}
                    <div className="px-5">
                        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-3 gap-1">
                            <div className="text-white p-4 col-span-2">
                                <div>
                                    <label  className="block mb-2 text-sm font-medium text-gray-300 ">Full Name <span className="text-red-600">*</span></label>
                                    <input type="text" id="full_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                                    <span className="text-red-500 text-xs">{formErrors.full_name}</span>
                                </div>
                                <div className="pt-2">
                                    <label  className="block mb-2 text-sm font-medium text-gray-300 ">Email <span className="text-red-600">*</span></label>
                                    <input type="text" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                                    <span className="text-red-500 text-xs">{formErrors.email}</span>
                                </div>
                                <div className="pt-2">
                                    <label  className="block mb-2 text-sm font-medium text-gray-300 ">Phone Number <span className="text-red-600">*</span></label>
                                    <input type="text" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                                    <span className="text-red-500 text-xs">{formErrors.phone}</span>
                                </div>
                            </div>
                            <div className="border rounded-md  text-white p-4">
                                <div className="w-full flex mb-3 items-center">
                                    <div className="flex-grow">
                                        <h4>Your order</h4>
                                    </div>
                                    <div className="pl-3">
                                        <span className="text-blue-700 text-lg">{namePlan}</span>
                                    </div>
                                </div>
                                <div className="py-5 pb-6 border-b border-gray-200 text-gray-100">
                                    <div className="w-full flex mb-3 items-center">
                                        <div className="flex-grow">
                                            <span className="text-gray-200">Price</span>
                                        </div>
                                        <div className="pl-3">
                                            <span className="font-semibold">${price}</span>
                                        </div>
                                    </div>
                                    <div className="w-full flex items-center">
                                        <div className="flex-grow">
                                            <span className="text-gray-200">Discount </span>
                                        </div>
                                        <div className="pl-3">
                                            <span className="font-semibold">0</span>
                                        </div>
                                    </div>
                                </div>
                                <div className=" pb-6 border-b border-gray-200 md:border-none text-gray-100 text-xl">
                                    <div className="w-full flex items-center">
                                        <div className="flex-grow">
                                            <span className="text-gray-200">Total</span>
                                        </div>
                                        <div className="pl-3">
                                            <span className="font-semibold text-gray-100 text-sm"></span> <span className="font-semibold">${price}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-1">
                                    <button type="submit" className="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Buy Now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}
