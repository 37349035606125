import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from '../../assets/images/channel/1.png';
import img2 from '../../assets/images/channel/2.png';
import img3 from '../../assets/images/channel/3.png';
import img4 from '../../assets/images/channel/4.png';
import img5 from '../../assets/images/channel/5.png';
import img6 from '../../assets/images/channel/6.png';
import img7 from '../../assets/images/channel/7.png';
import img8 from '../../assets/images/channel/8.png';
import img9 from '../../assets/images/channel/9.png';
import img10 from '../../assets/images/channel/10.png';
import img11 from '../../assets/images/channel/11.png';
import img12 from '../../assets/images/channel/12.png';
import { Fade } from "react-reveal";
export default function CaroucelChannel() {
    const channel = [
        { id: 1, image: img1 },
        { id: 2, image: img2 },
        { id: 3, image: img3 },
        { id: 4, image: img4 },
        { id: 5, image: img5 },
        { id: 6, image: img6 },
        { id: 7, image: img7 },
        { id: 8, image: img8 },
        { id: 9, image: img9 },
        { id: 10, image: img10 },
        { id: 11, image: img11 },
        { id: 12, image: img12 },
    ];
    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToShow: 7,
        slidesToScroll: 7,
        autoplay: true,
        focusOnSelect: true,
        speed: 1000,
        autoplaySpeed: 3000,
        nextArrow: <div className="hidden"></div>,
        prevArrow: <div className="hidden"></div>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    infinite: true,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    };
    return (
        <div className="">
            <Fade bottom duration={1500} className="p-5 pb-5  m-2 rounded-lg" style={{ boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px' }}>

                <Slider className="!w-[-webkit-fill-available] mr-10 ml-10 align-slider" {...settings} arrows={false}>
                    {
                        channel.map((item) => {
                            return (
                                <div key={item.id} className="!flex justify-center">
                                    <img className="w-[7pc] md:w-[9pc]  object-cover image-carousel-device" src={item.image}  />
                                </div>

                            )
                        })
                    }
                </Slider>
            </Fade>
        </div>
    )
}
