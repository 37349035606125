import CardWyh from "./components/CardWyh";
import CaroucelChannel from "./components/CaroucelChannel";
import CaroucelMovie from "./components/CaroucelMovie"
import { Button } from 'flowbite-react';
import PlanPay from "./components/PlanPay";
import HowWork from "./components/HowWork";
import ProductText from "./components/ProductText";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from 'react-scroll';
import CaroucelDevices from "./components/CaroucelDevices";
import { useEffect, useRef } from "react";
import HeadContent from "../helper/HeadContent";
import BottomWhatssap from "./components/BottomWhatssap";
import { useLocation, useParams } from "react-router-dom";

export const Home = () => {
    const plansRef = useRef(null);
    const location = useLocation();
    const hash = window.location.hash;
    if (hash) {
        const element = document.querySelector(hash);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    useEffect(() => {
        // Apply the style to the body tag when the Home component mounts
        document.body.classList.add('home-page');

        // Clean up when the component unmounts
        return () => {
            document.body.classList.remove('home-page');
        };
    }, []);
    const description = 'Gatestreame: Elevate Your Entertainment. Explore our premium IPTV services for limitless viewing pleasure. Choose from a variety of subscription plans tailored to your preferences. Join us now for seamless streaming and exclusive content.'

    return (
        <div className="body">
            <HeadContent title={'Home'} description={description} />
            <BottomWhatssap />
            <div className=" text-[#fff] section-bg">
                <div className="container mx-auto grid md:grid-cols-2  gap-10" >
                    <div className="pt-10  pl-5 ">
                        <h1 className="text-stone-200 text-sm text-start font-medium uppercase">
                            The Ultimate IPTV Experience Begins Now.
                        </h1>
                        <h1 className="text-2xl text-stone-100 font-sans font-bold pt-5 md:text-lg lg:text-2xl">
                            Unlock a World of Entertainment with Our
                            <span className="text-[#3b82f6] italic"> Premium IPTV</span> Service
                        </h1>
                        <p className="text-[#FFF] text-justify pt-5 pr-5">
                            Experience high-quality streaming, an extensive channel lineup, and on-demand content tailored to your preferences. Join us today for a TV revolution!
                        </p>
                        <div className="pt-14 flex justify-start md:justify-end">
                            <Link to="plan" smooth={true} duration={500}>
                                <Button color="blue">
                                    Choose plan
                                    <FaArrowRightLong className="ml-2 h-5 w-5" />
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative top-4 text-white">
                <div className="flex justify-center align-center">
                    <CaroucelMovie />
                </div>
                <div className="mt-4">
                    <CaroucelChannel />
                </div>
                <div className="mt-4 ">
                    <HowWork />
                </div>
                <div className="mt-4 mx-5" id="plan" ref={plansRef}>
                    <PlanPay /> 
                </div>
                <div className="mt-4 ">
                    <CardWyh />
                </div>
                <div className="mt-4 ">
                    <ProductText />
                </div>
                <div className="mt-4 mb-8">
                    <CaroucelDevices />
                </div>
            </div>
        </div>
    )
}