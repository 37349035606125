import img2 from '../../assets/images/pic/3.png'
import { Fade } from "react-reveal";
import { Link } from 'react-scroll';
import { Button } from 'flowbite-react';
import { FaArrowRightLong } from "react-icons/fa6";
export default function ProductText() {
    return (
        <div>
            <div className="grid  md:grid md:grid-cols-2 md:items-center gap-4 p-8 bg-black">
                <Fade bottom duration={1500} >
                    <div className='text-white'>
                        <h1 className='text-blue-600 text-2xl font-bold font-serif'>Explore Prime Video (and more) on Emby.</h1>
                        <p className='text-justify text-sm md:text-base 2xl:text-xl'>With your free Emby account, you can choose your preferred streaming platforms (such as Prime Video) to uncover additional content, search efficiently, build a universal Watchlist, and receive personalized recommendations—all without leaving Emby.</p>
                        <div className="md:flex md:justify-end font-sans font-bold my-4">
                            <Link to="plan" smooth={true} duration={500}>
                                <Button color="blue">
                                    Choose plan
                                    <FaArrowRightLong className="ml-2 h-5 w-5" />
                                </Button>
                            </Link>
                        </div>
                    </div>
                </Fade>
                <Fade bottom duration={1500} >
                    <div className=''>
                        <img className='' src={img2} alt="Netflix Picture" />
                    </div>
                </Fade>
            </div>
        </div>
    )
}
