export const requiredValidator = (value) => {
    return value.trim() !== '' ? '' : 'This field is required';
};

export const emailValidator = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) ? '' : 'Invalid email address';
};

export const phoneNumberValidator = (value) => {
    // Customize the phone number validation regex based on your requirements
    const phoneNumberRegex = /^[0-9]+$/;
    return phoneNumberRegex.test(value) ? '' : 'Invalid phone number';
};

export const alphaValidator = (value) => {
    const alphaRegex = /^[a-zA-Z\s]+$/;
    return alphaRegex.test(value) ? '' : 'Only letters are allowed';
};

export const integerValidator = (value) => {
    const integerRegex = /^[0-9]+$/;
    return integerRegex.test(value) ? '' : 'Please enter a valid number';
};
