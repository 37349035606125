import { Helmet } from 'react-helmet-async';

const HeadContent = ({ title, description }) => {
    return (
        <Helmet>
            <title>GateStreame | {title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content="IPTV, entertainment, streaming, channels, exclusive content, digital entertainment, subscription plans, seamless streaming" />
        </Helmet>
    );
};

export default HeadContent;
