import React from 'react'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import { nav } from './Nav'
import { AuthProvider } from '../auth/Auth'
import { ScrollLink } from 'react-scroll';

export const RanderRoutes = () => {
    const { user } = AuthProvider();
    return (
        <Routes>
            {nav.map((route, index) => {
                if (route.isPrivate && !user.isAuth) {
                    return <Route key={index} path={route.path} element={<Navigate to="/not-found" replace />} />;
                } else {
                    return <Route key={index} path={route.path} element={route.element} />;
                }

            })}
        </Routes>
    );
};

// export const RenderMenu = () => {
//     const { user } = AuthProvider();

//     const MenuItem = ({ route }) => {
//         return (
//             <div className="menuItem"><Link to={route.path}>{route.name}</Link></div>
//         );
//     };

//     return (
//         <div className="menu">
//             {nav.map((route, index) => {
//                 if (!route.isPrivate && route.isMenu) {
//                     return (
//                         <MenuItem key={index} route={route} />
//                     );
//                 } else if (user.isAuth && route.isMenu) {
//                     return (
//                         <MenuItem key={index} route={route} />
//                     );
//                 } else return false;
//             })}
//         </div>
//     );
// };

export const RenderMenu = () => {
    const { user } = AuthProvider();
    const isLogin = user.name
    const MenuItem = ({ route }) => {
        if (route.path === '/#plan') {
            return (
                <ScrollLink className={isLogin ? 'px-3 py-2 w-[13pc] hover:bg-slate-200 rounded' : ''} to="plan" smooth={true} duration={500}>{route.name}</ScrollLink>
            );
        } else {
            return (
                <Link className={isLogin ? 'px-3 py-2 w-[13pc] hover:bg-slate-200 rounded' : ''} to={route.path}>{route.name}</Link>
            );
        }
    };
    // const MenuItem = ({ route }) => (
    //     <Link className={isLogin ? 'px-3 py-2 w-[13pc] hover:bg-slate-200 rounded' : ''} to={route.path}>{route.name}</Link>
    // );

    return (
        <div className={isLogin ? 'flex flex-col gap-1 ' : ''}>
            {nav
                .filter((route) => user.isAuth && route.isMenu && route.isPrivate)
                .map((route, index) => (
                    <MenuItem key={index} route={route} />
                ))}
        </div>
    );
};
