import axios from 'axios';

const API_KEY = '31b85b9dde2a41d5c7ef0d1a13d9484c';
const ACCESS_TOKEN = 'eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIzMWI4NWI5ZGRlMmE0MWQ1YzdlZjBkMWExM2Q5NDg0YyIsInN1YiI6IjY0ZTY3YTM1ZTg5NGE2MDEwMTIwMjAyOSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.P-NCUy-XW01_lS6wvDQC6TsmbfQXjDAdylRhFMLWUwg';
export const BASE_URL_IMAGE = 'https://image.tmdb.org/t/p/w500/'
export const fetchNowPlaying = async () => {
    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${ACCESS_TOKEN}`
        }
    };

    try {
        const response = await fetch('https://api.themoviedb.org/3/movie/now_playing?language=en-US&page=1', options);
        const data = await response.json();
        return data.results;
    } catch (err) {
        console.error(err);
        // You can decide what to do in case of an error, e.g., throw an error or return a default value
        throw err;
    }
};


