import React, { useEffect, useState } from 'react'
import { FaPlus } from "react-icons/fa6";
import { Button } from 'flowbite-react';
import AddPlan from './AddPlan';
import axios from '../../plugins/axios';
import { Spinner } from 'flowbite-react';
import { GoTrash } from "react-icons/go";
import { CiEdit } from "react-icons/ci";
import { MdVerified } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";
import { Modal } from 'flowbite-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeleteItem = ({ planId, refresh }) => {
    const [openModal, setOpenModal] = useState(false);

    const notify = (msg) => {
        toast.success(msg, {});
    };
    const handleDelete = async () => {
        try {
            const response = await axios.delete(`/plan/${planId}`);
            setOpenModal(false);
            notify(response.data.message)
            refresh();
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>
            <Button outline color="failure" onClick={() => setOpenModal(true)}><GoTrash /></Button>
            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>Delete Plan</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Are you sure you want to delete this plan?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="failure" onClick={handleDelete}>Delete Plan</Button>
                    <Button color="gray" onClick={() => setOpenModal(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}











const EditItem = ({ planId, refresh, selectedPlan }) => {
    const [fields, setFields] = useState(['']);
    const [isLoading, setIsLooding] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({
        name: selectedPlan.name,
        price: selectedPlan.price,
        interval: selectedPlan.interval,
        nbrInterval: selectedPlan.nbrInterval,
        popular: selectedPlan.popular,
        link: selectedPlan.link,
        features: JSON.parse(selectedPlan.features),
    });
    console.log(selectedPlan);
    const notify = (message) => {
        toast.success(message, {});
    };
    const notifyError = (error) => {
        toast.error(error, {});
    };

    const handleAddField = (e) => {
        e.preventDefault();
        setFields([...fields, '']);

        setFormData((prevData) => ({
            ...prevData,
            features: [...prevData.features, ''],
        }));
    };

    // const handleRemoveField = (index) => {
    //     if (fields.length > 1) {
    //         const updatedFields = [...fields];
    //         updatedFields.splice(index, 1);
    //         setFields(updatedFields);

    //         const updatedFeatures = [...formData.features];
    //         updatedFeatures.splice(index, 1);
    //         setFormData((prevData) => ({
    //             ...prevData,
    //             features: updatedFeatures,
    //         }));
    //     }
    // };

    const handleRemoveField = (index) => {
        const updatedFields = [...fields];
        updatedFields.splice(index, 1);
        setFields(updatedFields);

        const updatedFeatures = [...formData.features];
        updatedFeatures.splice(index, 1);

        // Check if the features array is empty and handle it accordingly
        const newFeatures = updatedFeatures.length > 0 ? updatedFeatures : [''];

        setFormData((prevData) => ({
            ...prevData,
            features: newFeatures,
        }));
    };


    const onCloseModal = () => {
        setOpenModal(false);
    };
    const handleChange = (index, value, fieldName) => {
        setFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index] = value;
            return updatedFields;
        });

        setFormData((prevData) => {
            if (fieldName === 'features') {
                const updatedFeatures = [...prevData.features];
                updatedFeatures[index] = value;
                return { ...prevData, features: updatedFeatures };
            }
            return prevData;
        });
    };



    const handleCheckboxChange = (e) => {
        setFormData({ ...formData, popular: e.target.checked });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLooding(true);
            const response = await axios.post(`/plan/${planId}`, formData);
            notify(response.data.message)
            setIsLooding(false);
            refresh()
            setOpenModal(false);

        } catch (error) {
            notifyError(error.response.data.error)
            setIsLooding(false);

        }
    };
    return (
        <>
            <Button onClick={() => setOpenModal(true)} outline color="success"><CiEdit /></Button>
            <Modal show={openModal} size="xl" onClose={onCloseModal} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6">
                        <h3 className="text-xl font-medium text-gray-900 ">Add New Plan</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                                <div className="w-full">
                                    <label for="name" className="block mb-2 text-sm font-medium text-gray-900 ">Name Plan</label>
                                    <input type="text" name="name" id="name" value={formData.name}
                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Plan name" required />
                                </div>
                                <div className="w-full">
                                    <label for="price" className="block mb-2 text-sm font-medium text-gray-900 " min='0'>Price</label>
                                    <input type="number" name="price" id="price" value={formData.price}
                                        onChange={(e) => setFormData({ ...formData, price: parseFloat(e.target.value) })} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="$299" required />
                                </div>

                                <div className="w-full">
                                    <label for="nbrInterval" className="block mb-2 text-sm font-medium text-gray-900 ">Number Interval</label>
                                    <input type="number" name="nbrInterval" id="nbrInterval" value={formData.nbrInterval}
                                        onChange={(e) => setFormData({ ...formData, nbrInterval: parseInt(e.target.value) })} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="6" required />

                                </div>
                                <div className="w-full">
                                    <label for="interval" className="block mb-2 text-sm font-medium text-gray-900 ">Interval</label>
                                    <select id="interval" value={formData.interval}
                                        onChange={(e) => setFormData({ ...formData, interval: e.target.value })} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" required>
                                        <option selected="">Month</option>
                                        <option value="Years">Year</option>
                                    </select>
                                </div>
                                <div className="sm:col-span-2">
                                    {formData.features.map((field, index) => (
                                        <div key={index} className="mb-4">
                                            <label for={`features_${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                                                Features Item Number {index + 1}
                                            </label>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    value={field}
                                                    onChange={(e) => handleChange(index, e.target.value, 'features')}
                                                    name={`features_${index}`}
                                                    id={`features_${index}`}
                                                    className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 pr-10"
                                                    placeholder="Features"
                                                    required
                                                />
                                                <button
                                                    onClick={() => handleRemoveField(index)}
                                                    className="absolute inset-y-0 right-0 flex items-center justify-center px-2.5 bg-red-500 text-white rounded-r-lg"
                                                >
                                                    <GoTrash />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <Button onClick={handleAddField} color="dark" className="w-full">
                                        Add
                                    </Button>

                                </div>
                                <div className="sm:col-span-2">
                                    <label for="link" class="block mb-2 text-sm font-medium text-gray-900 " min='0'>Link</label>
                                    <input type="text" name="link" id="link" value={formData.link}
                                        onChange={(e) => setFormData({ ...formData, link: e.target.value })} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="https://www.test.com/" required />
                                </div>
                                <div className='flex justify-between sm:col-span-2 items-center'>
                                    <div>
                                        <input
                                            id="popular"
                                            type="checkbox"
                                            checked={formData.popular}
                                            onChange={handleCheckboxChange}
                                            className="text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2"
                                        />
                                        <label for="popular" className="ms-2 text-sm font-medium text-gray-900">
                                            Popular
                                        </label>
                                    </div>
                                    {isLoading ? (
                                        <button disabled type="submit" className=" flex aling-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                            </svg>
                                            Loading...
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                        >
                                            Save
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>

                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default function Plans() {
    const [openModal, setOpenModal] = useState(false);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [loading, setLoading] = useState(true);

    function onCloseModal() {
        setOpenModal(false);
    }

    const fetchPlans = async () => {
        try {
            const response = await axios.get('/plans');
            setSubscriptionPlans(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching plans:', error);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchPlans();
    }, []);
    return (
        <div className='px-4 py-4  bg-white border border-gray-200 rounded-lg shadow'>
            <div className='flex justify-end'>
                <Button className='bg-blue-600 hover:bg-blue-800' onClick={() => setOpenModal(true)}><FaPlus />
                </Button>
                <AddPlan onCloseModal={onCloseModal} openModal={openModal} refresh={fetchPlans} />
            </div>

            {loading ? (
                <div className='flex justify-center items-center h-[50vh]'>
                    <Spinner color="info" aria-label="Info spinner example" />
                </div>)
                : (
                    <div className="relative overflow-x-auto mt-4 ">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Name Plan
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Interval
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Price
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Features
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Link
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Popular
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {subscriptionPlans.map((plan) => (
                                    <tr key={plan.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            {plan.name}
                                        </td>
                                        <td className="px-6 py-4">{plan.nbrInterval} {plan.interval}</td>
                                        <td className="px-6 py-4">{plan.price}</td>
                                        <td className="px-6 py-4">
                                            <ul>
                                                {JSON.parse(plan.features).map((feature, index) => (
                                                    <li key={index}>- {feature}</li>
                                                ))}
                                            </ul>
                                        </td>
                                        <td className="px-6 py-4">{plan.link}</td>
                                        <td className="px-6 py-4">{plan.popular ? <MdVerified size={20} color='#008000' /> : <AiFillCloseCircle size={20} color='#FF0000' />}</td>
                                        <td className="px-6 py-4 flex gap-2">
                                            <EditItem planId={plan.id} refresh={fetchPlans} selectedPlan={plan} />
                                            <DeleteItem planId={plan.id} refresh={fetchPlans} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

        </div>
    )
}
