import React from 'react'
import { MdSupportAgent } from "react-icons/md";
import { GrChannel } from "react-icons/gr";
import { FaWifi } from "react-icons/fa6";
import { Fade } from 'react-reveal';

const data = [
    {
        icon: FaWifi,
        title: "ACCESS EVERYWHERE",
        text: "Enjoy access from anywhere, anytime. Your entertainment, wherever you are."
    },
    {
        icon: GrChannel,
        title: "+10000 INTERNATIONAL CHANNELS",
        text: "Access over 10,000 international channels, bringing the world's entertainment right to your screen."
    },
    {
        icon: MdSupportAgent,
        title: "24/7 SUPPORT",
        text: "Get peace of mind with our 24/7 support team, ready to assist you anytime, anywhere."
    }
];

export default function CardWyh() {
    // const [data, setData] = useState([1, 2, 3])
    return (
        <Fade bottom duration={1500}>
            <div className='flex justify-center align-center '>
                <h4 className='text-2xl py-8 font-bold'>VALUE-PACKED IPTV SERVICES.</h4>
            </div>
            <div className='flex flex-wrap justify-center align-center gap-4 '>
                {data.map((item) => (
                    <div key={item} className="max-w-sm p-6  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <item.icon size={60} className='py-2 text-blue-400' />
                        <p href="#">
                            <h4 className="mb-2 text-xl font-semibold tracking-tight text-white dark:text-white">{item.title}</h4>
                        </p>
                        <p className="mb-3 font-normal text-gray-200 ">{item.text}</p>
                    </div>
                ))}
            </div>
        </Fade>


    )
}
