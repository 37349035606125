import { MdOutlineVideoLibrary } from "react-icons/md";
import { MdOutlineSwitchAccount } from "react-icons/md";
import { MdShopTwo } from "react-icons/md";

import { Fade } from "react-reveal";
const steps = [
    {
        id: 1,
        title: "Place Your Subscription Order",
        icon: MdShopTwo,
        text: "Choose your preferred subscription duration: 1, 3, 6, or 12 months.",
    },
    {
        id: 2,
        title: "Account Activation",
        icon: MdOutlineSwitchAccount,
        text: "Anticipate your account activation in 15 to 30 minutes.Monitor both your inbox and spam folder, and for quicker assistance, feel free to contact us on WhatsApp.",
    },
    {
        id: 3,
        title: "Indulge in Seamless IPTV Enjoyment",
        icon: MdOutlineVideoLibrary,
        text: "Explore a world of entertainment with diverse channels, captivating films, binge-worthy series, and catch exciting matches.",
    },
];
export default function HowWork() {
    return (
        <div>
            <Fade bottom duration={1500}>
                <div className='flex justify-center align-center'>
                    <h4 className='text-2xl py-8 font-bold text-center'>How does it works?</h4>
                </div>
                <div className='flex flex-wrap justify-center align-center gap-4 '>
                    {steps.map((item) => (
                        <div key={item.id} className="max-w-sm p-6  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                            <div className="flex justify-start align-center">
                                <item.icon size={70} className='py-2 text-blue-400' />
                            </div>
                            <p href="#">
                                <h4 className="mb-2 text-xl font-semibold tracking-tight text-white dark:text-white">{item.title}</h4>
                            </p>
                            <p className="mb-3 font-normal text-gray-200 ">{item.text}</p>
                        </div>
                    ))}
                </div>
            </Fade>
        </div>
    )
}
