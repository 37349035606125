import { FaWhatsapp } from "react-icons/fa";

export default function BottomWhatssap() {
    const handleClick = () => {
        const whatsappLink = "https://wa.me/+212633002038?text=hello%20gate%20streame";
        window.open(whatsappLink, "_blank");
    };
    return (
        <div>
            <button onClick={handleClick} className="float">
                <FaWhatsapp />
            </button>
        </div>
    )
}
